const IconArrowCircleSlider = ({
  fill = '#BFE1FC',
  className,
  dataTestid = 'icon-arrow-slider'
}) => (
  <svg
    data-testid={dataTestid}
    className={className}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      fill={fill}
    />
    <path
      d="M26.9413 31.9977L37.7955 21.3907C38.2869 20.91 38.2869 20.1394 37.7955 19.6587C37.5498 19.4184 37.2278 19.3024 36.9143 19.3024C36.6008 19.3024 36.2788 19.4184 36.0331 19.6587L24.2977 31.1359C24.1791 31.2519 24.0859 31.3845 24.0266 31.5336C24.0266 31.5336 24.0266 31.5419 24.0266 31.5502C23.9673 31.6911 23.9418 31.8402 23.9418 31.9977C23.9418 32.1551 23.9757 32.3043 24.0266 32.4452C24.0266 32.4452 24.0266 32.4535 24.0266 32.4618C24.0859 32.6109 24.1791 32.7518 24.2977 32.8595L36.0246 44.345C36.516 44.8173 37.304 44.8173 37.7955 44.345C38.2869 43.8726 38.2785 43.0937 37.7955 42.613L26.9413 31.9977Z"
      fill="#171A1A"
    />
  </svg>
);

export default IconArrowCircleSlider;
